<template>
  <div>
    <!-- <div class="title">网络课程</div> -->
    <div class="rightcontent">
      <div
        v-for="item in videolist"
        :key="item.id"
        class="videolistdiv"
        @click="godetails(item.id)"
      >
        <div class="imgdiv">
          <img
            class="img"
            v-if="item.videoImage"
            :src="'/com/file/fileDownLoad?filePath=' + item.videoImage"
            alt=""
          />
        </div>
        <div class="videotitle">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="total <= 9"
        layout="prev, pager, next"
        :total="total"
        @current-change="handleChange"
        :current-page.sync="current"
        :page-size="size"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { pageVideoInfo } from "@/api/lectureHall.js";
export default {
  data() {
    return {
      current: 1,
      size: 9,
      total: 0,
      videolist: [], //网课列表
    };
  },
  mounted() {
    this.getvideolist();
  },
  methods: {
    handleChange(page) {
      this.current = page;
      this.getvideolist();
    },
    godetails(id) {
      this.$router.push({
        path: "/lectureHall/lectureHallvideolist/lectureHallvideodetail",
        query: { id: id },
      });
    },
    getvideolist() {
      // 网络课程
      pageVideoInfo({ size: this.size, current: this.current,type: 3 }).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          this.total = data.total || 0;
          this.videolist = data.records || [];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1ab394;
}
/deep/.el-pager li:hover {
  color: #1ab394 !important;
}
.rightcontent {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  .videolistdiv {
    width: 280px;
    margin: 10px;
    height: 190px;
    box-sizing: border-box;
    cursor: pointer;
    .imgdiv {
      width: 280px;
      box-sizing: border-box;
      height: 150px;
      .img {
        width: 280px;
        height: 150px;
      }
    }
    .videotitle {
      box-sizing: border-box;
      width: 280px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      background-color: #dcefe6;
      text-align: center;
    }
    .videolistdiv:hover {
      .videotitle {
        color: #1ab394;
      }
      // .img{
      //   transform: scale(1.2);
      // }
    }
  }
}
.pagination {
  margin-top: 40px;
}
</style>
